import React, {useEffect, useState} from "react";
import {graphql, Link} from "gatsby";

import Marquee from "react-fast-marquee";
import Layout from "../components/Layout";
import styled from "styled-components";

import {GatsbyImage} from "gatsby-plugin-image";
import BlogEntry from "../components/BlogEntry";
import TeamMember from "../components/TeamMember";
import BlogTag from "../components/BlogTag";
import Head from "../components/Head";

export const query = graphql`
    {
  allSanityAboutPage {
    edges {
      node {
        image1 {
          asset {
            gatsbyImageData
          }
        }
        image2 {
          asset {
            gatsbyImageData
          }
        }
        image3 {
          asset {
            gatsbyImageData
          }
        }
        marqueetext
        werte{
        title
        text
        image{
            asset{
                gatsbyImageData
            }
        }
       } 
        newsarticle {
          slug {
            current
          }
          title
          introtext
          imageoverview {
            asset {
              gatsbyImageData
            }
          }
          blogtag
        }
        featuredheadline
        featuredimage{
        asset{
                gatsbyImageData
            }
        }
        featuredtext
        team {
          jobbezeichnung
          title
          image {
            asset {
              gatsbyImageData
            }
          }
          beschreibung
          email
        }
      }
    }
  }
  allSanitySettings {
    edges {
      node {
        instalink
        fblink
        linkedinlink
      }
    }
  }   
}
`

const About = ({data}) => {

    const about = data.allSanityAboutPage.edges[0].node;

    var colors = [
        'f2c1b1',
        'b7b783',
        'ffffff',
    ];

    return (
        <Layout color={'ff603b'} settings={data.allSanitySettings.edges[0].node} landing={true}>
            <Head title="Über uns"/>

            <div className="about__landing__section">
                <div className="about__landing__image__container">
                    <div className="about__landing__image__wrapper">

                        <GatsbyImage image={about.image1.asset.gatsbyImageData} alt="About Startseite Bild"></GatsbyImage>
                        <GatsbyImage image={about.image2.asset.gatsbyImageData} alt="About Startseite Bild"></GatsbyImage>
                        <GatsbyImage image={about.image3.asset.gatsbyImageData} alt="About Startseite Bild"></GatsbyImage>
                        <div className="about__arrowdown__container">

                        </div>
                        <div className="about__landing__abs">
                            <svg className="rotate" version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                 viewBox="0 0 799.91 866.7">
                                <g>
                                    <g>
                                        <path className="svg-fill-white" d="M376.43,32.91L427.1,33v-9.39h-50.67V0h-7.9v56.62h7.9L376.43,32.91z M427.1,225.28h-58.58v9.48h58.58V225.28
			z M233.63,310.24l6.64,6.64l23.01-23.01c2.39-2.52,5.34-4.44,8.61-5.59c2.99-0.96,6.2-0.99,9.2-0.07
			c3.62,1.21,6.88,3.32,9.47,6.12c1.99,1.93,3.66,4.16,4.96,6.6c1.12,2.11,1.78,4.43,1.94,6.81c0.11,2.39-0.35,4.76-1.35,6.93
			c-1.23,2.62-2.93,5-5.03,7l-23.01,23.01l6.7,6.71l23.47-23.47c2.67-2.6,4.84-5.68,6.38-9.07c1.38-3.09,2.04-6.46,1.92-9.85
			c-0.17-3.52-1.1-6.97-2.73-10.1c-1.98-3.74-4.55-7.15-7.6-10.09c-3.69-3.89-8.17-6.94-13.15-8.94c-4.26-1.65-8.92-1.93-13.34-0.79
			c-4.83,1.38-9.19,4.03-12.62,7.69L233.63,310.24z M298.74,465.12v-58.58h-47.61v7.9h38.12v50.67L298.74,465.12z M309.69,386.31
			l63.12-19.72l-7.04-7.04l-13.28,4.2l-27.28-27.28l4.34-13.15l-7.37-7.37l-19.85,62.99L309.69,386.31z M322.51,344.3l22.15,22.15
			l-32.48,10.13L322.51,344.3z M375.82,198.56L375.82,198.56c2.6,3.1,5.85,5.6,9.53,7.3c8.02,3.45,17.11,3.47,25.15,0.05
			c3.65-1.68,6.88-4.16,9.44-7.25c2.73-3.37,4.77-7.24,6-11.39c1.44-4.76,2.14-9.71,2.09-14.69c0.01-3.41-0.27-6.82-0.84-10.18
			c-0.51-3.07-1.33-6.08-2.47-8.97c-0.93-2.44-2.16-4.75-3.67-6.88l-8.37,0.09c1.62,1.89,2.92,4.02,3.86,6.32
			c1.15,2.75,2.04,5.59,2.65,8.51c0.69,3.22,1.04,6.51,1.03,9.81c0.13,4.99-0.79,9.94-2.7,14.55c-1.59,3.81-4.27,7.06-7.71,9.34
			c-7.32,4.31-16.4,4.34-23.76,0.09c-3.48-2.22-6.21-5.42-7.86-9.2c-1.96-4.55-2.91-9.46-2.79-14.41c-0.01-3.04,0.28-6.08,0.88-9.07
			c0.55-2.74,1.37-5.42,2.47-8c0.95-2.27,2.23-4.39,3.81-6.28l-7.53-1.12c-1.59,1.95-2.88,4.13-3.81,6.46
			c-1.18,2.85-2.07,5.81-2.65,8.83c-0.66,3.32-0.99,6.7-0.98,10.09c-0.05,4.96,0.67,9.9,2.14,14.65
			C371,191.36,373.07,195.21,375.82,198.56z M574.62,460.15v-58.58h-9.48v58.58H574.62z M368.52,298.74h58.58v-47.61h-7.9v38.12
			h-50.68V298.74z M625.99,241.74c4.23-4,7.47-8.93,9.47-14.4c1.66-4.73,1.83-9.86,0.49-14.69c-3.53-10.54-11.81-18.82-22.36-22.35
			c-4.82-1.34-9.94-1.16-14.66,0.52c-5.45,2.03-10.37,5.28-14.37,9.5l-20.71,20.71l41.42,41.42L625.99,241.74z M589.83,206.24
			c2.81-2.96,6.28-5.22,10.12-6.58c3.39-1.14,7.05-1.22,10.49-0.23c3.83,1.2,7.28,3.36,10.03,6.28c2.9,2.75,5.08,6.17,6.34,9.96
			c1.07,3.43,1.05,7.1-0.07,10.52c-1.36,3.92-3.65,7.45-6.68,10.29l-13.67,13.67l-30.24-30.24L589.83,206.24z M501.17,401.58v58.58
			h47.61v-7.9h-38.12v-50.68H501.17z M559.92,307.81l-16.57-16.57l8.88-8.88l34.12-0.99l8.81-8.81l-35.03,1.1l0.31-0.32
			c2.84-2.7,5.09-5.96,6.61-9.57c2.63-6.11,1.11-13.21-3.78-17.72c-2.31-2.49-5.33-4.22-8.64-4.96c-3.16-0.58-6.42-0.16-9.34,1.18
			c-3.59,1.67-6.84,4-9.57,6.87l-23.93,23.94l41.42,41.42L559.92,307.81z M523.89,271.78l16.44-16.44c2.98-2.98,5.82-4.68,8.51-5.1
			c2.69-0.42,5.29,0.62,7.79,3.12c1.38,1.31,2.41,2.95,2.99,4.77c0.5,1.81,0.35,3.74-0.4,5.46c-1.04,2.35-2.55,4.46-4.44,6.21
			l-16.44,16.44L523.89,271.78z M372.81,641.41h58.58v-9.48h-58.58L372.81,641.41z M372.81,567.96v47.61h7.9v-38.12h50.67
			l-0.01-9.48H372.81z M420.67,364.22l-7.17,7.17l56.67,26.17l6.77-6.77l-21.43-45.36l45.23,21.57l6.91-6.91l-26.1-56.74l-7.04,7.03
			l21.7,45.24l-45.11-21.3l-6.31,6.31l21.24,45.43L420.67,364.22z M539.14,328.58l-41.42-41.42l-6.71,6.71l41.42,41.42
			L539.14,328.58z M427.1,120.22h-25.2V81.73h25.2v-9.67h-58.58v9.67h25.57v38.49h-25.57v9.48h58.58V120.22z M491.15,475.32
			l-63.12,19.72l7.03,7.04l13.28-4.2l27.28,27.28l-4.34,13.15l7.36,7.36l19.85-62.99L491.15,475.32z M478.33,517.34l-22.16-22.15
			l32.48-10.13L478.33,517.34z M424.08,668.15L424.08,668.15c-2.59-3.11-5.85-5.6-9.52-7.3c-8.03-3.45-17.12-3.47-25.15-0.04
			c-3.65,1.68-6.88,4.15-9.44,7.25c-2.73,3.37-4.77,7.24-6,11.39c-1.44,4.76-2.14,9.71-2.09,14.69c-0.01,3.41,0.27,6.82,0.84,10.18
			c0.51,3.07,1.33,6.08,2.46,8.98c0.93,2.44,2.16,4.75,3.67,6.88l8.37-0.09c-1.62-1.89-2.92-4.02-3.86-6.32
			c-1.15-2.75-2.04-5.59-2.65-8.51c-0.69-3.22-1.04-6.51-1.03-9.81c-0.13-4.99,0.79-9.94,2.7-14.55c1.59-3.81,4.27-7.06,7.72-9.34
			c7.32-4.31,16.4-4.34,23.75-0.09c3.48,2.22,6.21,5.42,7.86,9.2c1.96,4.55,2.91,9.46,2.79,14.41c0.01,3.04-0.28,6.08-0.88,9.07
			c-0.55,2.74-1.37,5.42-2.46,8c-0.95,2.27-2.23,4.39-3.81,6.28l7.53,1.12c1.59-1.95,2.88-4.13,3.82-6.47
			c1.18-2.85,2.06-5.81,2.65-8.83c0.66-3.32,0.99-6.7,0.98-10.09c0.05-4.96-0.67-9.9-2.14-14.64
			C428.9,675.35,426.83,671.49,424.08,668.15z M372.81,746.48h25.2v38.49h-25.2v9.67h58.58v-9.67h-25.57v-38.49h25.57v-9.48h-58.58
			L372.81,746.48z M567.21,551.39l-6.64-6.64l-23.01,23.01c-2.39,2.52-5.34,4.44-8.62,5.59c-2.99,0.96-6.2,0.99-9.2,0.07
			c-3.62-1.22-6.87-3.32-9.47-6.12c-1.99-1.93-3.66-4.16-4.96-6.61c-1.12-2.1-1.78-4.42-1.94-6.8c-0.11-2.39,0.35-4.76,1.35-6.93
			c1.23-2.63,2.93-5,5.03-7.01l23.01-23.01l-6.71-6.71l-23.47,23.47c-2.68,2.6-4.84,5.68-6.38,9.07c-1.38,3.1-2.04,6.47-1.91,9.86
			c0.17,3.52,1.1,6.97,2.73,10.09c1.98,3.74,4.55,7.15,7.6,10.09c3.69,3.89,8.17,6.94,13.15,8.94c4.26,1.65,8.93,1.93,13.35,0.79
			c4.82-1.38,9.19-4.03,12.62-7.69L567.21,551.39z M423.48,833.78l-50.67-0.09v9.39h50.67v23.62h7.9v-56.63h-7.9V833.78z
			 M718.18,427.15h-38.49v-25.57h-9.49v58.58h9.48v-25.2h38.49v25.2h9.67v-58.58h-9.67L718.18,427.15z M607.92,593.61L607.92,593.61
			c-1.2-2.06-2.52-4.04-3.95-5.93c-1.49-1.97-3.12-3.84-4.87-5.58c-3.45-3.57-7.41-6.61-11.74-9.04c-3.32-1.94-7.1-2.95-10.95-2.93
			c-3.42,0.13-6.65,1.59-9,4.08c-1.39,1.34-2.49,2.93-3.26,4.7c-0.67,1.6-0.99,3.33-0.92,5.07c0.1,1.89,0.54,3.75,1.28,5.49
			c0.89,2.11,1.96,4.13,3.22,6.05c1.5,2.3,3.13,4.52,4.87,6.64c2.19,2.54,4.09,4.83,5.69,6.87c1.43,1.77,2.66,3.69,3.68,5.72
			c0.81,1.56,1.16,3.31,0.99,5.06c-0.28,1.76-1.15,3.37-2.47,4.57c-1.34,1.33-3.14,2.09-5.03,2.14c-2.39,0.02-4.74-0.65-6.77-1.91
			c-3.08-1.86-5.9-4.13-8.38-6.74c-1.7-1.7-3.31-3.5-4.8-5.39c-2.84-3.57-5.06-7.6-6.58-11.9c-0.69-1.95-1.13-3.99-1.31-6.05
			l-5.92,5.91c0.1,1.69,0.49,3.35,1.15,4.9c0.81,1.99,1.75,3.92,2.83,5.78c1.22,2.13,2.57,4.19,4.04,6.15
			c1.56,2.09,3.28,4.07,5.13,5.91c3.68,3.85,7.94,7.12,12.62,9.67c3.44,1.92,7.34,2.86,11.27,2.73c3.49-0.24,6.76-1.78,9.17-4.3
			c1.73-1.63,3.08-3.62,3.98-5.82c0.71-1.89,0.98-3.91,0.79-5.92c-0.22-2.09-0.78-4.13-1.65-6.05c-0.96-2.15-2.13-4.2-3.49-6.12
			c-1.43-2.04-3.02-4.11-4.77-6.21c-2.76-3.38-5.01-6.32-6.74-8.84c-1.48-1.95-2.5-4.21-2.99-6.61c-0.27-1.86,0.39-3.73,1.78-5
			c1.33-1.43,3.26-2.13,5.19-1.9c2.34,0.36,4.57,1.26,6.51,2.63c2.64,1.76,5.08,3.78,7.3,6.05c1.56,1.56,3.02,3.22,4.37,4.96
			c1.38,1.78,2.62,3.66,3.72,5.62c1.06,1.89,1.94,3.87,2.63,5.92c0.62,1.81,1.04,3.68,1.25,5.59l5.79-5.79
			c-0.11-1.56-0.45-3.1-1.02-4.57C609.86,597.28,608.97,595.4,607.92,593.61z M743.28,401.58v7.9h23.71l-0.09,50.67h9.39v-50.67
			h23.62v-7.9L743.28,401.58z M646.96,449.6L646.96,449.6c-2.75,1.15-5.59,2.03-8.51,2.65c-3.22,0.69-6.51,1.04-9.81,1.02
			c-4.99,0.13-9.94-0.79-14.55-2.7c-3.81-1.59-7.06-4.27-9.34-7.72c-4.31-7.32-4.34-16.4-0.09-23.76c2.22-3.48,5.42-6.21,9.2-7.86
			c4.55-1.96,9.46-2.91,14.41-2.79c3.04-0.01,6.08,0.28,9.06,0.88c2.74,0.55,5.42,1.37,8,2.46c2.27,0.95,4.39,2.23,6.28,3.81
			l1.12-7.53c-1.95-1.59-4.13-2.87-6.47-3.81c-2.85-1.18-5.81-2.07-8.83-2.65c-3.32-0.66-6.7-0.99-10.09-0.98
			c-4.96-0.05-9.9,0.67-14.64,2.14c-4.14,1.27-8,3.34-11.35,6.09c-3.11,2.6-5.6,5.86-7.3,9.53c-3.45,8.02-3.47,17.11-0.05,25.15
			c1.68,3.65,4.15,6.88,7.25,9.44c3.37,2.73,7.24,4.77,11.39,6c4.76,1.44,9.71,2.14,14.69,2.09c3.41,0.01,6.82-0.27,10.18-0.84
			c3.07-0.51,6.08-1.33,8.98-2.46c2.44-0.93,4.75-2.16,6.88-3.67l-0.09-8.37C651.4,447.36,649.26,448.67,646.96,449.6z
			 M187.21,463.91L187.21,463.91c4.15-1.27,8-3.34,11.35-6.09c3.11-2.6,5.6-5.85,7.3-9.53c3.45-8.02,3.47-17.11,0.05-25.15
			c-1.68-3.65-4.16-6.88-7.25-9.44c-3.37-2.73-7.24-4.77-11.39-6c-4.76-1.44-9.71-2.14-14.69-2.09c-3.41-0.01-6.82,0.27-10.18,0.84
			c-3.07,0.51-6.08,1.33-8.97,2.47c-2.44,0.93-4.75,2.16-6.88,3.67l0.09,8.37c1.89-1.62,4.02-2.92,6.32-3.86
			c2.75-1.15,5.59-2.04,8.51-2.65c3.22-0.69,6.51-1.04,9.81-1.02c4.99-0.13,9.94,0.79,14.55,2.7c3.81,1.59,7.06,4.27,9.34,7.72
			c4.31,7.32,4.34,16.4,0.09,23.76c-2.22,3.48-5.43,6.21-9.21,7.86c-4.54,1.96-9.46,2.91-14.41,2.79c-3.04,0.01-6.08-0.28-9.07-0.88
			c-2.74-0.55-5.42-1.37-8-2.46c-2.27-0.95-4.39-2.23-6.28-3.81l-1.12,7.53c1.95,1.59,4.13,2.87,6.46,3.81
			c2.85,1.18,5.81,2.07,8.83,2.65c3.32,0.66,6.7,0.99,10.09,0.98C177.53,466.1,182.47,465.38,187.21,463.91z M33.01,406.55h-9.39
			v50.67H0v7.9h56.62v-7.9H32.91L33.01,406.55z M225.29,465.12h9.48v-58.58h-9.48V465.12z M120.22,431.74H81.73v-25.2h-9.67v58.58
			h9.67v-25.57h38.49v25.57h9.48v-58.58h-9.48L120.22,431.74z M192.92,268.03l0.01,0c1.19,2.05,2.51,4.03,3.95,5.92
			c1.49,1.97,3.12,3.84,4.86,5.59c3.45,3.57,7.4,6.61,11.73,9.04c3.32,1.94,7.1,2.95,10.95,2.92c3.42-0.13,6.65-1.59,9-4.08
			c1.39-1.34,2.49-2.93,3.26-4.7c0.67-1.6,0.98-3.33,0.92-5.06c-0.1-1.89-0.54-3.75-1.28-5.49c-0.88-2.11-1.96-4.14-3.22-6.05
			c-1.5-2.3-3.13-4.52-4.87-6.64c-2.19-2.54-4.09-4.83-5.69-6.87c-1.43-1.77-2.66-3.69-3.68-5.72c-0.81-1.56-1.16-3.31-0.99-5.06
			c0.28-1.76,1.15-3.37,2.47-4.57c1.34-1.33,3.14-2.09,5.03-2.14c2.39-0.02,4.74,0.64,6.77,1.91c3.08,1.86,5.9,4.13,8.38,6.74
			c1.7,1.7,3.31,3.5,4.8,5.39c2.84,3.57,5.06,7.6,6.58,11.9c0.69,1.96,1.13,3.99,1.31,6.05l5.92-5.92c-0.1-1.69-0.49-3.34-1.15-4.9
			c-0.81-1.99-1.75-3.92-2.83-5.78c-1.22-2.13-2.57-4.18-4.05-6.15c-1.56-2.1-3.28-4.07-5.13-5.92c-3.68-3.86-7.94-7.12-12.62-9.66
			c-3.44-1.92-7.34-2.87-11.27-2.73c-3.49,0.24-6.76,1.77-9.18,4.3c-1.73,1.63-3.09,3.62-3.98,5.82c-0.71,1.89-0.98,3.91-0.79,5.92
			c0.22,2.09,0.78,4.13,1.65,6.05c0.96,2.15,2.13,4.19,3.49,6.12c1.43,2.04,3.02,4.11,4.77,6.21c2.76,3.38,5.01,6.32,6.74,8.84
			c1.48,1.95,2.5,4.21,2.99,6.61c0.27,1.86-0.39,3.73-1.77,5c-1.33,1.43-3.26,2.13-5.19,1.9c-2.34-0.37-4.57-1.27-6.51-2.63
			c-2.64-1.76-5.08-3.79-7.3-6.05c-1.56-1.56-3.02-3.22-4.37-4.96c-1.38-1.78-2.62-3.66-3.71-5.62c-1.06-1.89-1.94-3.87-2.63-5.92
			c-0.62-1.81-1.04-3.69-1.25-5.59l-5.78,5.79c0.11,1.57,0.45,3.1,1.02,4.57C190.97,264.36,191.86,266.24,192.92,268.03z
			 M379.24,502.48l7.17-7.17l-56.67-26.17l-6.77,6.77l21.43,45.36l-45.23-21.57l-6.9,6.9l26.1,56.74l7.04-7.03l-21.7-45.23
			l45.11,21.31l6.31-6.31l-21.24-45.43L379.24,502.48z M173.91,624.97c-4.23,4-7.47,8.93-9.47,14.4c-1.66,4.73-1.83,9.86-0.49,14.69
			c1.59,5.26,4.52,10,8.52,13.77c4.38,4.38,9,7.24,13.84,8.58c4.82,1.34,9.94,1.16,14.66-0.52c5.45-2.03,10.37-5.28,14.37-9.5
			l20.71-20.71l-41.42-41.42L173.91,624.97z M210.07,660.47c-2.81,2.96-6.28,5.22-10.12,6.58c-3.39,1.14-7.05,1.22-10.49,0.23
			c-3.83-1.2-7.28-3.36-10.03-6.28c-2.9-2.75-5.08-6.17-6.35-9.96c-1.07-3.43-1.05-7.11,0.07-10.52c1.36-3.92,3.65-7.45,6.68-10.29
			l13.67-13.68l30.24,30.24L210.07,660.47z M260.76,538.11l41.42,41.42l6.71-6.71l-41.42-41.42L260.76,538.11z M239.99,558.89
			l16.57,16.57l-8.88,8.88l-34.12,0.99l-8.81,8.81l35.03-1.1l-0.32,0.32c-2.84,2.7-5.09,5.96-6.61,9.57
			c-2.63,6.11-1.11,13.21,3.78,17.72c2.32,2.49,5.33,4.22,8.64,4.96c3.16,0.58,6.42,0.16,9.34-1.18c3.59-1.67,6.83-4,9.57-6.87
			l23.93-23.94l-41.42-41.42L239.99,558.89z M276.02,594.92l-16.44,16.44c-2.98,2.98-5.82,4.68-8.51,5.1
			c-2.69,0.42-5.29-0.62-7.79-3.12c-1.38-1.32-2.41-2.95-2.99-4.77c-0.5-1.81-0.35-3.74,0.4-5.46c1.04-2.35,2.55-4.46,4.44-6.22
			l16.44-16.44L276.02,594.92z"/>
                                    </g>
                                </g>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>

            <Marquee gradient={false} className="marqueeWrapper fixedHeight" speed={40}>
                <div className="marqueeText color-orange about">
                    <span className="marqueeText__inner">{about.marqueetext}</span>
                    <span className="marqueeText__inner">{about.marqueetext}</span>
                    <span className="marqueeText__inner">{about.marqueetext}</span>
                    <span className="marqueeText__inner">{about.marqueetext}</span>
                    <span className="marqueeText__inner">{about.marqueetext}</span>
                    <span className="marqueeText__inner">{about.marqueetext}</span>
                    <span className="marqueeText__inner">{about.marqueetext}</span>
                </div>
            </Marquee>
            <div className="werte__container">
                {about.werte.map((node, index) => (
                    <div className="wert__wrapper">
                        <GatsbyImage image={node.image.asset.gatsbyImageData} alt={node.title} className="wert__img"></GatsbyImage>
                        <div className="wert__overlay"></div>
                        <div className="wert__text__wrapper">
                            <div className={"wert__nr nr" +  index}>{index+1}</div>
                            <h2 className="uppercase wert__headline">{node.title}</h2>
                            <p className="small wert__text">{node.text}</p>
                        </div>
                    </div>
                ))
                }
            </div>
            <Marquee gradient={false} className="marqueeWrapper color-whiteonblack news" speed={40}>
                <div className="marqueeText">
                    <span className="marqueeText__inner">NEWS</span>
                    <span className="marqueeText__inner">NEWS</span>
                    <span className="marqueeText__inner">NEWS</span>
                    <span className="marqueeText__inner">NEWS</span>
                    <span className="marqueeText__inner">NEWS</span>
                    <span className="marqueeText__inner">NEWS</span>
                    <span className="marqueeText__inner">NEWS</span>
                    <span className="marqueeText__inner">NEWS</span>
                    <span className="marqueeText__inner">NEWS</span>
                    <span className="marqueeText__inner">NEWS</span>
                    <span className="marqueeText__inner">NEWS</span>
                    <span className="marqueeText__inner">NEWS</span>
                    <span className="marqueeText__inner">NEWS</span>
                    <span className="marqueeText__inner">NEWS</span>
                    <span className="marqueeText__inner">NEWS</span>
                    <span className="marqueeText__inner">NEWS</span>
                </div>
            </Marquee>
            <div className="news__container">
                <div className="blogList about">
                    {console.log(about.newsarticle)}
                    {about.newsarticle.map((blog, index) => {
                        console.log(blog);
                        if(blog.slug){
                            return (<Link to={"../blog/" + blog.slug.current}>
                                { blog.imageoverview.asset ? <BlogEntry nobutton title={blog.title} text={blog.introtext} slug={'../blog/' + blog.slug.current} tags={blog.blogtag} color={colors[(index % 3)]} image={blog.imageoverview.asset.gatsbyImageData}></BlogEntry> : <></>}
                            </Link>);
                        }
                    })}
                </div>
            </div>
            <div className="news__feature__container">
                <div className="blogfeature__container --about">
                    {about.featuredimage.asset ? <GatsbyImage image={about.featuredimage.asset.gatsbyImageData} alt={about.featuredheadline}></GatsbyImage> : <></>}
                    <div className="blogfeature__textwrapper about">
                    <h2>{about.featuredheadline}</h2>
                    </div>
                    <div className="blogfeature__textwrapper">

                        <p className="small">{about.featuredtext[0]}</p>
                        <p className="small">{about.featuredtext[1]}</p>
                        <p className="small">{about.featuredtext[2]}</p>
                    </div>
                </div>
            </div>
            <Marquee gradient={false} className="marqueeWrapper color-orange team" speed={40}>
                <div className="marqueeText team">
                    <span className="marqueeText__inner">
                        UNSER <br/>TEAM
                    </span>
                    <span className="marqueeText__inner">
                        UNSER <br/>TEAM
                    </span>
                    <span className="marqueeText__inner">
                        UNSER <br/>TEAM
                    </span><span className="marqueeText__inner">
                        UNSER <br/>TEAM
                    </span><span className="marqueeText__inner">
                        UNSER <br/>TEAM
                    </span>
                    <span className="marqueeText__inner">
                        UNSER <br/>TEAM
                    </span>
                    <span className="marqueeText__inner">
                        UNSER <br/>TEAM
                    </span>
                    <span className="marqueeText__inner">
                        UNSER <br/>TEAM
                    </span>
                    <span className="marqueeText__inner">
                        UNSER <br/>TEAM
                    </span>

                    <span className="marqueeText__inner">
                        UNSER <br/>TEAM
                    </span>
                    <span className="marqueeText__inner">
                        UNSER <br/>TEAM
                    </span><span className="marqueeText__inner">
                        UNSER <br/>TEAM
                    </span><span className="marqueeText__inner">
                        UNSER <br/>TEAM
                    </span><span className="marqueeText__inner">
                        UNSER <br/>TEAM
                    </span><span className="marqueeText__inner">
                        UNSER <br/>TEAM
                    </span><span className="marqueeText__inner">
                        UNSER <br/>TEAM
                    </span><span className="marqueeText__inner">
                        UNSER <br/>TEAM
                    </span><span className="marqueeText__inner">
                        UNSER <br/>TEAM
                    </span>
                </div>
            </Marquee>
            <div id="team" className="team__container">
                <div className="blogList team">
                    {about.team.map((member, index) => {
                        if(member.image){
                            return <TeamMember name={member.title} job={member.jobbezeichnung} email={member.email} text={member.beschreibung} img={member.image.asset.gatsbyImageData}></TeamMember>
                        }
                    })}
                </div>
            </div>

        </Layout>
    )
}

export default About;
